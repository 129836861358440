@import '../node_modules/normalize-scss/sass/normalize/import-now';
@import 'fonts';

%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

* {
  font-weight: 200;
  font-family: "Mark W01 Light", "Gill Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

h1, h2 {
  margin: 0;
  float: left;
  clear: left;
}

h1 {
  font-size: 28px;
  margin-top: 40px;
}

h2 {
  font-size: 16px;
  letter-spacing: -0.5px;
  margin-top: 4px;
}

p, address {
  font-size: 28px;
  font-style: normal;
}

body > div {
  width: 100%;
  max-width: 1024px;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 40px 20px;

  > * {
    @extend %clearfix;
  }

  > *+* {
    margin-top: 46px;
  }
}

header {
  .logo {
    float: left;
  }
}

@media only screen and (min-width: 768px) {
  header {
    .logo {
      float: right;
      margin-right: 23%;
    }
  }
}